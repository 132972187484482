import * as React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const Footer = () => (
  <div className="footer">
      <ul>
          <li><a href="tel:8327090034">(832) 409-0034</a></li>
          <li><a href="mailto:info@ponsolutions.com">info@ponsolutions.com</a></li>
            <li>© {new Date().getFullYear()} Pon Solutions, LLC</li>
          <li><Link to="/terms">Terms and Conditions</Link></li>
      </ul>
  </div>
);

export default connect()(Footer);
