import * as React from 'react';
import { connect } from 'react-redux';
import masthead from '../images/masthead.jpg';
import { TextAlignProperty } from 'csstype'

const mastheadStyle = {
    width: '100%',
    textAlign: 'center' as TextAlignProperty,
    color: 'white',
    overflow: 'hidden',
    background: `linear-gradient(rgb(72,0,72,0.8), rgb(192,72,72,0.8)), url(${masthead})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    height: '300px',
    backgroundPosition: 'center'
}

const Header = () => (
  <div className="header-container" style={mastheadStyle}>
    <div className="header-content">
        <h1>Software Consulting & Development</h1>
        <h4>Our dedicated team specializes in building custom solutions to meet your needs!</h4>
    </div>
  </div>
);

export default connect()(Header);
