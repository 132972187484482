import * as React from 'react';
import { connect } from 'react-redux';
import LicenseFile from '../static/SoftwareLicenses.txt';

const Terms = () => (
  <div>
    <h1>Terms and conditions</h1>
    <h3>
      Agreement between User and Pon Solutions, LLC
    </h3>
    <p>
      Welcome to <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a>. 
      The <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> website (the "Site") is comprised of various web pages operated by Pon Solutions, LLC ("Pon Solutions").&nbsp;
      <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> is offered to you conditioned on your acceptance without modification of the terms, conditions, and notices contained herin (the "Terms").
      Your use of <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> constitutes your agreement to all such Terms.
      Please read these tersm carefully, and keep a copy of them for your reference.
    </p>
    <h3>
      Electronic Communications
    </h3>
    <p>
      Visiting <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> or sending emails to Pon Solutions constitutes electronic communications.
      You consent to recieve electronic communications and you agree that all agreements, noticies, disclosures and other communications that we provide to you electronicall, via email and on the Site, satisfy any legal requirement that such communcations be in writing.
    </p>
    <h3>
      Children Under Thirteen
    </h3>
    <p>
      Pon Solutions does not knowingly collect, either online or offline, personal information from persons under the age of thirteen.
      If you are under 18, you may use <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> only with permission of a parent or guardian.
    </p>
    <h3>
      Links to Third Party Sites/Third Party Services
    </h3>
    <p>
      <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> may contain links to other websites ("Linked Sites").
      The Linked Sites are not under the control of Pon Solutions and Pon Solutions is not responsible for the contents of any Linked Site, including without limitation any link contained in a Linked Site, or any changes or updates to a Linked Site.
      Pon Solutions is poviding these links to you only as a convenience, and the inclusion of any link does not imply endorsement by Pon Solutions of the site or any association with its operators.
    </p>
    <p>
      Certain services made available via <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> are delivered by third party sites and organizations. By using any product, service or functionality originating from the <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> domain, you hereby acknowledge and consent that Pon Solutions may share such information and data with any third party with whom Pon Solutions has a contractual relationship to provide the requested product, service, or functionality on behalf of <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> users and customers.
    </p>
    <h3>
      No Unlawful or Prohibited Use/Intellectual Property
    </h3>
    <p>
      You are granted a non-exclusive, non-transferable, revocable license to access and use <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> strictly in accordance with these terms of use. As a condition of your use of the Site, you warrant to Pon Solutions that you will not use the Site for any purpose that is unlawful or prohibited by these Terms. You may not use the site in any manner which could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site.
      You may not obtain or attempt to obtain any materials or information through any means not intentionally made available or provided for through the Site.
    </p>
    <p>
      All content included as part of the Service, such as text, graphics, logos, images, as well as the compilation thereof, and any software used on the Site, is the Property of Pon Solutions, licensed through third parties as identified at the end of this document, or its suppliers and protected by copyright and other laws that protect intellectual property and proprietary rights.
      You agree to observe and abite by all copyright and other proprietary notices, legends or other restrictions contained in any such content and will not make any changes thereto.
    </p>
    <p>
      You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.
      Pon Solutions content is not for resale.
      Your use of the Site does not entitle you to make any unauthorized use of any protected content, and in particular you will not delete or alter any proprietary rights or attribution noticies in any content.
      You will use protected content soley for your personal use, and will make no other use of the content without the express written permission of Pon Solutions and the copyright owner.
      You agree that you do not acquire any ownership rights in any protected content.
      We do not grant you any licenses, express or implied, to the intellectual property of Pon Solutions or our licensors exept as expressly authorized by these Terms.
    </p>
    <h3>
      International Users
    </h3>
    <p>
      The Service is controlled, operated and administered by Pon Solutions from our offices within the USA. If you access the Service from a location outside the USA, you are responsible for compliance with all local laws. You agree that you will not use the Pon Solutions Content accessed through <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> in any country or in any manner prohibited by any applicable laws, restrictions or regulations.
    </p>
    <h3>
      Indemnification
    </h3>
    <p>
      You agree to indemnify, defend and hold harmless Pon Solutions, its officers, directors, employees, agents, and third parties, for any losses, costs, liabilities, and expenses (including reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or services, any user postings made by you, your violation of any rights of a third party, or your vilation of any applicable laws, rules, or regulations.
      Pon Solutions reserves the right, at its own cost, to assime the exclusive defense and control of any matter otherwise subject to indemnification by you, in which event you will fully cooperate with Pon Solutions in asserting any available defenses.
    </p>
    <h3>
      Arbitration
    </h3>
    <p>
      In the event the parties are not able to resolve any dispute between them arising out of or concerning these Terms and Conditions, or any provisions herof, whether in contract, tort, or otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single neutral arbitarator and administered by the American Arbitration Association, or a similar arbitration service selected by the parties, in a location mutually agreed upon by the parties.
      The arbitrator's award shall be final, and judgement amy be entered upon it in any court having jurisdiction.
      In the event that any legal or equitable action, proceeding or arbitration arises out of or concerns these Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable attorneyt's fees.
      The parties agree to arbitrate all disputes and claims in regards to these Terms and Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or indirectly, including Tort claims that are a result of these Terms and Conditions.
      The parties agree that the Federal Arbitration Act governs the interpretation and enforcement of this provision.
      The entire dispute, including the scope and enforceability of this arbitration provision shall be determined by the Arbitrator.
      This arbitration provision shall survive the termination of these Terms and Conditions.
    </p>
    <h3>
      Class Action Waiver
    </h3>
    <p>
      Any arbitration under these Terms and Conditions will take place on an individual basis; class arbitrations and class/representative/collective actions are not permitted.
      THE PARTIES AGREE THAT A PARTY MAY BRING CLAIMS AGAINST THE OTHER ONLY IN EACH'S INDIVIDUAL CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PUTATIVE CLASS, COLLECTIVE AND/ OR REPRESENTATITVE PROCEEDING, SUCH AS IN THE FORM OF A PRIVATE ATTOURNEY GENERAL ACTION AGAINST THE OTHER.
      Further, unless both you and Pon Solutions agree otherwise, the arbitrator may not consolidate more than one person's claims, and may not otherwise preside over any form of a representative or class proceeding.
    </p>
    <h3>
      Liability Disclaimer
    </h3>
    <p>
      THE INFORMATION, SOFTWARE PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS.
      CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HERIN.
      PON SOLUTIONS, LLC AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME
    </p>
    <p>
      PON SOLUTIONS, LLC AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABLITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PUROSE.
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFROMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND.
      PON SOLUTIONS, LLC AND/OR ITS SUPPLIERS HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS, INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.
    </p>
    <p>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL PON SOLUTIONS, LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES, OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA, OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SOFTWARE, PRODUCTS, SERVICES, AND RELATED GRAPHICS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF PON SOLUTIONS, LLC OR ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
      BECAUSE IN SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
      IF YOU ARE DISSATISFIED WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
    </p>
    <h3>
      Termination/Access Restriction
    </h3>
    <p>
      Pon Solutions reserves the right, in its sole discretion, to terminate your access to the Site and the related services or any portion thereof at any time, without notice.
      To the maximum extent permitted by law, this agreement is governed by the laws of the State of Texas and you hereby consent to the exclusive jurisdiction and venue of courts in Texas in all disputes arising out of or relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give effect to all provisions of these Terms, including,without limitation, this section.
    </p>
    <p>
      You agree that no joint venture, partnership, employment, or agency relationship exists between you and Pon Solutions as a result of this agreeement or use of the Site.
      Pon Solutions' right to comply with governmental, court and law enforcement requests or requirements relating to your use of the Site or information provided to or gathered by Pon Solutions with respect to such use.
      If any part of this agreement is determined to be invalid or unenforceable pursuant to applicable law including, but not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or unenforceable provision will be deemed superseded by a valid, enforceable provision that most closely matches the intent of the original provision and the reaminder of the agreement shall continue in effect.
    </p>
    <p>
      Unless otherwise specified herein, this agreement constitutes the entire agreement between the user and Pon Solutions with respect to the Site and it supersedes all prior or contemporaneous communications and proposals, whether electronic, oral, or written, between the user and Pon Solutions with respect to the Site.
      A printed version of this agreement and any notice given in electronic form shal be admissible in judicial or administrative proceedings based upon or relating to this agreement to the same extent and subject to the same conditions as other business documents and records orginally generated and maintained in printed form. It is the express wish to the parties that this agreement and all related documents be written in English.
    </p>
    <h3>
      Changes to Terms
    </h3>
    <p>
      Pon Solutions reserves the right, in its sole discretion, to chage the Terms under which <a href="https://www.ponsolutions.com">https://www.ponsolutions.com</a> is offere. The most current version of the Terms will supersede all previous versions. Pon Solutions encourages you to periodically review the Terms to stay informed of out updates.
    </p>
    <h3>
      Contact Us
    </h3>
    <p>
      Pon Solutions welcomes your questions or comments regarding the Terms:
      <p>
        <a href="tel:8327090034">(832) 409-0034</a>
      </p>
      <p>
        <a href="mailto:info@ponsolutions.com">info@ponsolutions.com</a>
      </p>
      Effective as of September 5, 2021
    </p>
    <h3>
      Licensing
    </h3>
    <p>
      Image credits to:<br/>
      <a href="https://openclipart.org">https://openclipart.org</a><br/>
      <a href="https://www.pngtosvg.com/">https://www.pngtosvg.com</a><br/>
      <a href="https://pxhere.com">https://pxhere.com</a>
    </p>
    <p>
      Pon Solutions, LLC does not have any affiliation with technologies mentioned on this Site and all Trademarks, Names, Intellectual Property, and Copyrights are held with the entities that provide them.
    </p>
    <p>
      <a href={LicenseFile} target="_blank" rel="noopener noreferrer">Software licenses</a>
    </p>
  </div>
);

export default connect()(Terms);
