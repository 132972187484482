import * as React from 'react';
import { connect } from 'react-redux';
import AngularLogo from '../images/angular.svg'
import AwsLogo from '../images/aws.svg'
import AzureLogo from '../images/azure.svg'
import DotNetLogo from '../images/dotnet.svg'
import ReactLogo from '../images/react.svg'
import WebDevelopmentLogo from '../images/webdevelopment.svg'
import WindowsServicesLogo from '../images/windowsservices.svg'
import WinFormsLogo from '../images/winforms.svg'

const Home = () => (
  <div>
    <h1>Mission</h1>
    <p>
      Our mission at Pon Solutions is to provide an amazing experience from start to finish of your software solution. 
      We are committed to providing best in class software to meet your needs and exceed your expectations.
    </p>
    <h1>
      Quality
    </h1>
    <p>
      Whether you are looking to augment your onsite development or offload your development to us, we follow industry standards for code quality.
      By providing robust and scalable solutions, you can have the peace of mind that our team can continue to support your application well into the future.
    </p>
    <h1>Security</h1>
    <p>
      Security is one of our top concerns throughout every step of the process.
      Not only will we provide you with a great application, but it is also being built with best practices when it comes to cybersecurity.
    </p>
    <h1>Local</h1>
    <p>
      We are located in the Greater Houston Area and can meet with you onsite when required.
      None of our solutions are developed with offshore talent to ensure your experience is seamless.
    </p>
    <h1>Technologies</h1>
    <p>
      <ul className="three-column-list">
        <li>
          <img className="logo" src={WebDevelopmentLogo} alt="Web Development Logo" />
          Web Development
        </li>
        <li>
          <img className="logo" src={WindowsServicesLogo} alt="Windows Services Logo" />
          Windows Services
        </li>
        <li>
          <img className="logo" src={WinFormsLogo} alt="WinForms Logo" />
          WinForms & WPF
        </li>
        <li>
          <img className="logo" src={AngularLogo} alt="Angular Logo" />
          Angular
        </li>
        <li>
          <img className="logo" src={ReactLogo} alt="React Logo" />
          React
        </li>
        <li>
          <img className="logo" src={DotNetLogo} alt="Dot Net Logo" />
          .Net
        </li>
        <li>
          <img className="logo" src={AzureLogo} alt="Azure Logo" />
          Azure
        </li>
        <li>
          <img className="logo" src={AwsLogo} alt="AWS Logo" />
          AWS
        </li>
      </ul>
    </p>
  </div>
);

export default connect()(Home);
